import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/auth.context";
import StepperCard from "../components/StepperCard";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import { httpRequest } from "../utils/http.util";
import ConceptProcess from "../components/ConceptProcess";
import { useError } from "../contexts/error.context";


const ConceptForm = () => {
    const [activeTab, setActiveTab] = useState("projectDetails"); // Tracks the current tab
    const [formData, setFormData] = useState({
        projectName: "",
		productURL: "",
        brand: "",
        product: "",
        category: "",
        persona: [""],
        competition: [""],
        conceptName: "",
        description: "",
        conceptDetails: [
            {
                conceptTitle: "",
                consumerInsight: "",
                benefitStatement: "",
                supportingClaims: "",
                conceptName: "Concept C1",
                conceptImage: null,
                elements: null,
            },
        ],
        // conceptDetails: [
        //     {
        //         conceptTitle: "",
        //         consumerInsight: "",
        //         benefitStatement: "",
        //         supportingClaims: "",
        //         conceptImage: null,
        //         elements: null,
        //     },
        // ],
    });

    const { user } = useAuth();
    const { handleAPIError } = useError();
    const [inputValue, setInputValue] = useState("");
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [brandDropdownVisible, setBrandDropdownVisible] = useState(false);
    const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);
    const [selectedPersonas, setSelectedPersonas] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isExpanded, setIsExpanded] = useState([true]);
    const [brandGuidLineData, setBrandGuideLineData] = useState(null) //tempName
    const [detailsResponse, setDetailsResponse] = useState(null);
    const [personaDropdownVisible, setPersonaDropdownVisible] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [runId, setRunId] = useState(null);
    const [generatedProductId, setGeneratedProductId] = useState(null);

    const toggleForm = () => {
        setIsExpanded(!isExpanded);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const handleSelection = (persona) => {
        if (selectedPersonas.includes(persona)) {
            setSelectedPersonas((prev) =>
                prev.filter((selected) => selected !== persona)
            );
        } else {
            setSelectedPersonas((prev) => [...prev, persona]);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };



    const options = ["Brand A", "Brand B", "Brand C"];

    const categoryOptions = [
        "Household Cleaning",
        "Personal Care",
        "Electronics",
        "Grocery",
        "Stationery",
    ];

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));
    // };

    const handleChange = (e, index, field) => {
        const { name, value } = e.target;
        //console.log("Handle change", field, name, value);
        if (field === "conceptDetails") {
            setFormData((prev) => {
                const updatedConceptDetails = [...prev.conceptDetails];
                updatedConceptDetails[index][name] = value;
                return { ...prev, conceptDetails: updatedConceptDetails };
            });
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleCompetitionChange = (index, value) => {
        setFormData((prev) => {
            const updatedCompetition = [...prev.competition];
            updatedCompetition[index] = value;
            return { ...prev, competition: updatedCompetition };
        });
    };

    const addCompetitionField = () => {
        // Check if the last input is not empty before adding a new one
        if (formData.competition[formData.competition.length - 1]?.trim() !== "") {
            setFormData((prev) => ({
                ...prev,
                competition: [...prev.competition, ""],
            }));
        }

    };


    const handleFormDataChange = (index, field, value) => {
        const updatedConcepts = [...formData.conceptDetails];
        updatedConcepts[index][field] = value;
        setFormData((prev) => ({
            ...prev,
            conceptDetails: updatedConcepts,
        }));
    };

    const handleAddConcept = () => {
        setFormData((prev) => {
            // Calculate the next concept name
            const nextConceptNumber = prev.conceptDetails.length + 1;
            const nextConceptName = `Concept C${nextConceptNumber}`;
    
            return {
                ...prev,
                conceptDetails: [
                    ...prev.conceptDetails,
                    {
                        conceptTitle: "",
                        consumerInsight: "",
                        benefitStatement: "",
                        supportingClaims: "",
                        conceptName: nextConceptName, // Use dynamically generated name
                        conceptImage: null,
                        elements: null,
                    },
                ],
            };
        });
    
        // Automatically expand the new concept form
        setIsExpanded((prev) => [...prev, true]);
    };
    

    const toggleConcept = (index) => {
        setIsExpanded((prev) => {
            const updatedExpandedState = [...prev];
            updatedExpandedState[index] = !updatedExpandedState[index];
            return updatedExpandedState;
        });
    };

    const handleSubmit = async () => {
        //console.log('Handle Submit');

        // e.preventDefault();

        //await Promise.all([uploadProjects(), ...uploadConcepts]);
        //console.log("All files uploaded successfully.");
        httpRequest({
            method: "POST",
            url: `https://ct-projects-673130516998.asia-south1.run.app/v1/concept_testing/`,
            // data: {
            //     "project_name": formData.projectName,
            //     "brand_name": formData.brand,
            //     "product_name": formData.product_name,
            //     "product_url": formData.product_url,
            //     "market": formData.market,
            //     "category": formData.category,
            //     "personas": [
            //         formData.persona
            //     ],
            //     "competition_urls": formData.competition[
            //         "string"
            //     ]
            // },
            isAuthRequired: true,
        })
            .then((response) => {
                console.log("Fetch Project Id: ", response);
                setGeneratedProductId(response.data);
                uploadProjects(response.data);
                // uploadConcepts();

            })
            .catch(handleAPIError);
        // onClose();

    };

    const uploadProjects = (projectId) => {
        //console.log('Upload project start', formData);

        httpRequest({
            method: "POST",
            url: "https://ct-projects-673130516998.asia-south1.run.app/v1/concept_testing/" + projectId + "/project_details",
            data: {
                "project_name": formData.projectName,
                "brand_name": formData.brand,
                "product_name": formData.product,
                "product_url": formData.productURL,
                "market": '',
                "category": formData.category,
                "personas": formData.persona,
                "competition_urls": formData.competition
            },
            isAuthRequired: true,
        }).then((response) => {
                //console.log("Form Submitted: ", response);
                uploadConcepts(projectId);
            })
            .catch(handleAPIError);
    }

    const uploadConcepts = async (projectId) => {
        try {
            const conceptUploadPromises = formData.conceptDetails.map(async (concept, index) => {
                const formData1 = new FormData();
                if (concept.conceptImage) {
                    formData1.append("concept_image", concept.conceptImage);
                }
                if (concept.elements) {
                    formData1.append("elements_image", concept.elements);
                }
                if (concept.conceptName) {
                    formData1.append("concept_name", concept.conceptName);
                }
                if (concept.conceptTitle) {
                    formData1.append("title", concept.conceptTitle);
                }
                if (concept.consumerInsight) {
                    formData1.append("consumer_insight", concept.consumerInsight);
                }
                if (concept.benefitStatement) {
                    formData1.append("benefit_statement", concept.benefitStatement);
                }
                if (concept.supportingClaims) {
                    formData1.append("supporting_claims_and_reasons_to_believe", concept.supportingClaims);
                }
    
                return httpRequest({
                    method: "POST",
                    url: "https://ct-projects-673130516998.asia-south1.run.app/v1/concept_testing/" + projectId + "/concept_elements_and_images",
                    isAuthRequired: true,
                    data: formData1,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
            });
    
            // Wait for all concept uploads to complete
            await Promise.all(conceptUploadPromises);
            // Only start API after all concepts are uploaded
            startApi(projectId);
			
            setIsFormSubmitted(true);
        } catch (error) {
            console.error('Failed to upload concepts:', error);
            handleAPIError(error);
        }
    }

    const startApi = (projectId) => {
		// projectId = "Ar1hkNPCoJhTIDTC9brF_4"
        
        httpRequest({
            method: "POST",
            url: `https://ct-projects-673130516998.asia-south1.run.app/v1/concept_testing/${projectId}/start`,
            isAuthRequired: true,
        }).then((response) => {
                console.log(response.data.run_id);
                setRunId(response.data.run_id);
        })
        .catch(handleAPIError);
    }

    const isNextButtonEnabled = () => {
        if (activeTab === "projectDetails") {
            return formData.projectName.trim() !== "";
        } else if (activeTab === "addConcepts") {
            return formData.conceptName.trim() !== "" && formData.description.trim() !== "";
        }
        return false;
    };

    const handleNext = () => {
        //console.log("testing", activeTab)
        if (activeTab === "projectDetails") {
            setActiveTab("addConcepts");
        } else {
            handleSubmit();
        }
    };

        const handleFileChange = (e, index, field) => {
        const file = e.target.files[0];
        //console.log(file)
        setFormData((prev) => {
            const updatedConceptDetails = [...prev.conceptDetails];
            updatedConceptDetails[index][field] = file;
            return { ...prev, conceptDetails: updatedConceptDetails };
        });
    };

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleBrandOptionClick = (option) => {
        setFormData((prev) => ({
            ...prev,
            brand: option,
        }));
        setBrandDropdownVisible(false);
    };

    const handleCategoryOptionClick = (option) => {
        setFormData((prev) => ({
            ...prev,
            category: option,
        }));
        setCategoryDropdownVisible(false);
    };

    const handlePersonaToggle = (persona) => {
        // Update selectedPersonas state
        let updatedPersonas;
        if (selectedPersonas.includes(persona)) {
            updatedPersonas = selectedPersonas.filter((p) => p !== persona);
        } else {
            updatedPersonas = [...selectedPersonas, persona];
        }
    
        setSelectedPersonas(updatedPersonas);
    
        // Immediately update formData with the new selected personas
        setFormData((prev) => ({
            ...prev,
            persona: updatedPersonas, // Use the updated array here
        }));
    };

    const [brands, setBrands] = useState([]);

    useEffect(() => {
        httpRequest({
            method: "GET",
            url: `https://ct-projects-673130516998.asia-south1.run.app/v1/concept_testing/brand_categories_and_personas`,
    isAuthRequired: true,
        }).then((response) => {
    //console.log("Success:", response);
    setBrandGuideLineData(response.data);
    const data = response.data;
    const brnds = Object.keys(data);
    setBrands(brnds);
    setFormData((prev) => ({
        ...prev,
        brand: brnds[0],
    }));
})
    }, []);

const categories = brandGuidLineData ? brandGuidLineData[formData?.brand || ""]?.Categories || [] : [];
const personas = brandGuidLineData && formData.brand ? brandGuidLineData[formData.brand]?.Personas || [] : [];

if (isFormSubmitted && runId) {
    return (
        <ConceptProcess runId={runId} generatedProductId={generatedProductId}/>
    );
}

return (
    <div className="flex flex-col h-screen w-full overflow-auto font-poppins mt-14">
        <div className="hidden-scroll mx-auto flex-auto w-full lg:w-[812px] py-8 space-y-8 px-3">
            <div className="text-[32px] ">
                <span className="inline-flex items-center gap-x-2 font-semibold text-[#7A3DAA]">
                    Hello, {user?.displayName}
                    <img
                        src="/png/Waving Hand.png"
                        alt="Waving hand icon"
                        className="w-8 h-8"
                    />
                </span>
                <br />
                <span className="font-normal text-[#99A8C0]">
                    let's set up your new project
                </span>
            </div>

            <div className="space-y-4 my-4">
                <div className="flex items-center gap-x-1">
                    <StepperCard
                        isComplete={activeTab !== "projectDetails"}
                        isActive={activeTab === "projectDetails"}
                        step={1}
                        label="Project Name"
                        setStep={() => handleTabChange("projectDetails")}
                    />
                    <div className="w-10 border-[1.5px] border-dashed border-[#E7EBF1]" />
                    <StepperCard
                        isComplete={false}
                        isActive={activeTab === "addConcepts"}
                        step={2}
                        label="Add Concepts"
                        setStep={() => handleTabChange("addConcepts")}
                    />
                </div>
            </div>


            {/* Form Section */}
            <form onSubmit={handleSubmit} className="space-y-8">
                {activeTab === "projectDetails" && (
                    <div className="mt-6 space-y-6">

                        {/* Project Name  */}
                        <div className="mb-6 h-[83px] flex flex-col space-y-2">
                            <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span className="text-base font-medium">Project Name</span>
                            </label>
                            <input
                                type="text"
                                id="projectName"
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleChange}
                                placeholder="Enter project name here"
                                className="h-[47px] block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-indigo-500"
                                required
                            />
                        </div>


                        {/* Brand Section  */}
                        <div className="mb-6 relative h-[108px] flex flex-col space-y-2">

                            <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
                                <span className="text-base font-medium">Brand</span>
                            </label>
                            <p className="mb-2 text-sm text-[#536787]">
                                Enter the brand name associated with this product
                            </p>
                            <div className="relative">
                                <input
                                    id="brand"
                                    name="brand"
                                    value={formData.brand}
                                    onChange={handleInputChange}
                                    placeholder="Enter your Brand name"
                                    onClick={() => {
                                        setBrandDropdownVisible((prev) => !prev);
                                        setCategoryDropdownVisible(false);
                                    }}
                                    className="mt-1 h-[47px] block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 pr-10 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    autoComplete="off"
                                />
                                {/* Dropdown Icon here*/}
                                <div
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                                    onClick={() => setDropdownVisible(!isDropdownVisible)}
                                >
                                    <svg
                                        className="w-5 h-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </div>
                                {/* Dropdown Options */}
                                {brandDropdownVisible && (
                                    <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                                        {brands.map((option) => (
                                            <li
                                                key={option}
                                                onClick={() => handleBrandOptionClick(option)}
                                                className="px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-100"
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        {/* Product Name Section  */}
                        <div className="mb-6 ">
                            <label
                                htmlFor="projectName"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span className="text-base font-medium">Product Name</span>
                            </label>

                            <input
                                type="text"
                                id="projectName"
                                name="product"
                                value={formData.product}
                                onChange={handleChange}
                                placeholder="Enter the name of the product"
                                className="mt-1 h-12 block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-indigo-500"
                                required
                            />
                        </div>

                        {/* Product URL Section  */}
                        <div className="mb-6 ">
                            <label
                                htmlFor="productURL"
                                className="block text-sm font-medium text-gray-700"
                            >
                                <span className="text-base font-medium">Product URL</span>
                            </label>

                            <input
                                type="text"
                                id="productURL"
                                name="productURL"
                                value={formData.productURL}
                                onChange={handleChange}
                                placeholder="Enter the URL of the product"
                                className="mt-1 h-12 block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-indigo-500"
                                required
                            />
                        </div>

                        {/* Category Section */}
                        <div className="mb-6 relative">
                            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                                <span className="text-base">Category</span>
                            </label>
                            <p className="mb-2 text-sm text-[#536787]">
                                Choose the category that best fits your product
                            </p>
                            <div className="relative">
                                {/* Text Input */}
                                <input
                                    id="category"
                                    name="category"
                                    value={formData.category}
                                    onChange={handleInputChange}
                                    placeholder="Household Cleaning, Personal Care, etc."
                                    onClick={() => {
                                        setCategoryDropdownVisible((prev) => !prev);
                                        setBrandDropdownVisible(false); // for close other open dropdowns
                                    }}
                                    className="mt-1 h-12 block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-10 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    autoComplete="off"
                                />

                                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                                    <HiMiniMagnifyingGlass />
                                </div>

                                <div
                                    className="absolute inset-y-0 right-4 flex items-center cursor-pointer"
                                    onClick={() => setDropdownVisible(!isDropdownVisible)}
                                >
                                    <svg
                                        className="w-5 h-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </div>
                                {/* Dropdown Options */}
                                {(categories && categoryDropdownVisible) && (
                                    <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                                        {categories.map((option) => (
                                            <li
                                                key={option}
                                                onClick={() => handleCategoryOptionClick(option)}
                                                className="px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-100"
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        {/* Persona Section */}
                        <div className="relative">
                            <label className="block text-sm font-medium text-gray-700">Persona</label>
                            <input
                                value={selectedPersonas.join(", ")}
                                onClick={() => setPersonaDropdownVisible((prev) => !prev)}
                                readOnly
                                placeholder="Select Persona(s)"
                                className="mt-1 h-[47px] block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none"
                            />
                            {personaDropdownVisible && (
                                <ul className="absolute z-10 mt-1 w-full bg-white border rounded-lg shadow-lg">
                                    {personas.map((persona) => (
                                        <li
                                            key={persona}
                                            onClick={() => handlePersonaToggle(persona)}
                                            className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedPersonas.includes(persona)}
                                                onChange={() => handlePersonaToggle(persona)}
                                                className="mr-2"
                                            />
                                            {persona}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {/* Competiton Section */}
                        <div className="mb-6 flex flex-col space-y-2 ">
                            <div className="space-y-4">
                                <label
                                    htmlFor="competition"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    <span className="text-base">Competition</span>{" "}
                                    <span className="text-sm text-[#536787]">(Optional)</span>
                                </label>
                                <p className="text-sm text-[#536787]">
                                    Please provide the product's URL or ASIN for your competition. This makes the insights richer.
                                </p>

                                <div className="space-y-4">
                                    {formData.competition.map((value, index) => (
                                        <div key={index}>
                                            <input
                                                type="text"
                                                name={`competition-${index}`}
                                                value={value}
                                                onChange={(e) =>
                                                    handleCompetitionChange(index, e.target.value)
                                                }
                                                placeholder="For e.g. https://www.amazon.in/... or B07PI60BTW"
                                                className="h-12 block w-full rounded-lg border border-gray-300 bg-[#F7F9FC] py-2 px-4 text-gray-600 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                required={index === 0}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <button
                                    type="button"
                                    onClick={addCompetitionField}
                                    className={`flex h-[48px] items-center justify-center w-full border-2 border-dashed rounded-lg py-2 text-sm font-medium transition-colors ${formData.competition[formData.competition.length - 1]?.trim() !== ""
                                        ? "text-[#7A3DAA] cursor-pointer"
                                        : "text-[#7A3DAA] cursor-not-allowed"
                                        }`}
                                >
                                    + Add Competing Product
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                {activeTab === "addConcepts" && (
                    <div className="space-y-4 ">
                        {formData.conceptDetails.map((concept, index) => (
                            <div className="relative">
                                {/* Sticky Header Section */}
                                <div key={index} className="w-full rounded sticky top-0 z-10">
                                    {/* Header Section */}
                                    <div
                                        className="flex bg-[#7A3DAA0A] justify-between border h-[59px] items-center p-4 cursor-pointer rounded-md"
                                        onClick={() => toggleConcept(index)}
                                    >
                                        <h3 className="text-lg font-semibold">{concept.conceptName}</h3>
                                        <span className="text-gray-500 text-xl">
                                            {isExpanded[index] ? "▲" : "▼"}
                                        </span>

                                    </div>

                                    {/* Collapsible Form */}
                                    {isExpanded[index] && (
                                        <div className="w-full bg-white mt-4 border-gray-300">
                                            <form className="space-y-6 mt-6">
                                                {/* Concept Title */}
                                                <div className="h-[83px] flex flex-col space-y-2">
                                                    <label
                                                        htmlFor="concept-title"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Concept Title
                                                    </label>
                                                    <input
                                                    type="text"
                                                    id="conceptTitle"
                                                    name="conceptTitle"
                                                    value={concept.conceptTitle}
                                                    onChange={(e) =>
                                                        handleChange(e, index, "conceptDetails")
                                                    }
                                                    placeholder="Enter concept title here"
                                                    className="h-[47px] block w-full border border-gray-300 rounded-md p-2"
                                                />
                                                </div>

                                                {/* Consumer Insight */}
                                                <div>
                                                    <label
                                                        htmlFor="consumer-insight"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Consumer Insight
                                                    </label>
                                                    <textarea
                                                    id="consumerInsight"
                                                    name="consumerInsight"
                                                    value={concept.consumerInsight}
                                                    onChange={(e) =>
                                                        handleChange(e, index, "conceptDetails")
                                                    }
                                                    placeholder="Describe the problem or unmet need..."
                                                    className="mt-1 h-[90px] block w-full border border-gray-300 rounded-md p-2"
                                                ></textarea>
                                                </div>

                                                {/* Benefit Statement */}
                                                <div>
                                                    <label
                                                        htmlFor="benefit-statement"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Benefit Statement
                                                    </label>
                                                    <textarea
                                                    id="benefitStatement"
                                                    name="benefitStatement"
                                                    value={concept.benefitStatement}
                                                    onChange={(e) =>
                                                        handleChange(e, index, "conceptDetails")
                                                    }
                                                    placeholder="Highlight the primary benefit..."
                                                    className="mt-1 h-[90px] block w-full border border-gray-300 rounded-md p-2"
                                                ></textarea>
                                                </div>

                                                {/* Supporting Claims */}
                                                <div>
                                                    <label
                                                        htmlFor="supporting-claims"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Supporting Claims & Reasons to Believe
                                                    </label>
                                                    <textarea
                                                    id="supportingClaims"
                                                    name="supportingClaims"
                                                    value={concept.supportingClaims}
                                                    onChange={(e) =>
                                                        handleChange(e, index, "conceptDetails")
                                                    }
                                                    placeholder="List the specific features or claims..."
                                                    className="mt-1 h-[90px] block w-full border border-gray-300 rounded-md p-2"
                                                ></textarea>
                                                </div>

                                                {/* Concept Image */}
                                                <div>
                                                    <label
                                                        htmlFor="concept-image"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Concept Image
                                                    </label>
                                                    <div className="flex items-center space-x-2 mt-1">
                                                    <input
                                                    type="file"
                                                    id={`conceptImage-${index}`}
                                                    onChange={(e) =>
                                                        handleFileChange(e, index, "conceptImage")
                                                    }
                                                    className="h-[47px] block w-full border border-gray-300 rounded-md p-2"
                                                />
                                                    </div>
                                                </div>

                                                {/* Elements */}
                                                <div>
                                                    <label
                                                        htmlFor="elements"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Elements (Optional)
                                                    </label>
                                                    <div className="flex items-center space-x-2 mt-1">
                                                    <input
                                                    type="file"
                                                    id={`elements-${index}`}
                                                    onChange={(e) =>
                                                        handleFileChange(e, index, "elements")
                                                    }
                                                    className="h-[47px] block w-full border border-gray-300 rounded-md p-2"
                                                />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            className="w-full mt-4 py-2 text-white bg-[#7A3DAA] rounded-lg hover:bg-[#6a2996]"
                            onClick={handleAddConcept}
                        >
                            Add Concept
                        </button>
                    </div>
                )}


                <div className="flex justify-between mt-8">

                    <button
                        type="button"
                        onClick={() => setActiveTab("projectDetails")}
                        className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleNext}
                        className={`px-4 py-2 text-sm text-white rounded-md bg-[#7A3DAA]`}
                    >
                        {activeTab === "projectDetails" ? "Next" : "Submit"}
                    </button>
                </div>



            </form>
        </div>
    </div>
);
};

export default ConceptForm;
